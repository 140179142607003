import React from 'react'

const SvgDisabledAddCart = ({ title, ...props }) => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" {...props}>
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <circle cx={20} cy={20} r={20} fill="#EAEAEA" />
      <g fill="#6C6C6C" transform="translate(12 12)">
        <rect width={2} height={16} x={7} rx={1} transform="rotate(90 8 8)" />
        <rect width={2} height={16} x={7} rx={1} />
      </g>
    </g>
  </svg>
)

export default SvgDisabledAddCart
