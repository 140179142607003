import React, { Component } from 'react'
import styled from 'styled-components'
import differenceWith from 'lodash/differenceWith'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import VirtualArrow from './VirtualArrow'
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer'
import Grid from 'react-virtualized/dist/commonjs/Grid'
import { visible, SCREEN_SIZE, Media } from '../../lib/Media'

const DEFAULT_SCROLL_PERCENT = 0.8

const DIRECTIONS = {
  LEFT: 'left',
  RIGHT: 'right',
}

const WIDTH_SETTING_TIMEOUT_MS = 20

const VIRTUAL_GRID_CLASS = '.ReactVirtualized__Grid'

const SCROLL_DURATION_MS = 450

const DEFAULT_PRELOADED_ITEM_QUANTITY = 5

const ArrowContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;

  ${SCREEN_SIZE.Below.Tablet} {
    display: none !important;
  }

  ${({ direction, withBanner }) =>
    withBanner
      ? `${direction}: 0}`
      : direction === 'left'
      ? 'left: -1.25rem;'
      : 'right: -1.25rem;'}

  ${visible(SCREEN_SIZE.From.Desktop)}
`

const StyledCarouselWrapper = styled.div`
  ${VIRTUAL_GRID_CLASS} {
    position: relative;
    outline: none;
    width: 100%;

    ${SCREEN_SIZE.From.Desktop} {
      overflow-x: hidden !important;
    }

    &--left {
      left: -0.94rem;
    }

    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none; /*To hide scrollbar in firefox */
    -webkit-overflow-scrolling: touch;
  }
`

const ServerDataContainer = styled.div`
  display: flex;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none; /*To hide scrollbar in firefox */
  -webkit-overflow-scrolling: touch;
`

class VirtualCarousel extends Component {
  constructor(props) {
    super(props)

    this.state = {
      screenWidth: undefined,
      isClientSide: false,
      scrollLeft: 0,
    }

    this.cellRef = React.createRef()
  }

  currentScrollLeft = 0

  componentDidMount() {
    if (this.state.isClientSide === false && typeof window !== 'undefined') {
      this.setState({ isClientSide: true })
    }
  }

  isArrayEqual = (x, y) => {
    return isEmpty(differenceWith(x, y, isEqual))
  }

  componentDidUpdate(prevProps) {
    if (
      typeof window !== 'undefined' &&
      this.props.items &&
      !this.isArrayEqual(prevProps.items, this.props.items)
    ) {
      this.gridRef.scrollToPosition({
        scrollTop: 1,
        scrollLeft: 0,
      })
      this.currentScrollLeft = 0
      this.setState({ scrollLeft: 0 })
      this.gridRef.recomputeGridSize()
    }
  }

  cellRenderer = ({ columnIndex, key, style }) => {
    const { children, items } = this.props
    return (
      <div style={style} key={key} ref={this.cellRef} role="row">
        {children({ item: items[columnIndex], index: columnIndex })}
      </div>
    )
  }

  handleClickDirection = direction => {
    const SCROLL_PERCENT = this.props?.scrollPercent || DEFAULT_SCROLL_PERCENT
    const carouselWidth =
      this.carouselWrapper.offsetWidth || this.state.screenWidth
    if (direction === DIRECTIONS.LEFT) {
      this.currentScrollLeft -= carouselWidth * SCROLL_PERCENT
      if (this.currentScrollLeft < 0) {
        this.currentScrollLeft = 0
      }
    }

    const cellWidth = this.cellRef.current?.clientWidth || this.globalCellWidth
    this.globalCellWidth = cellWidth

    const maxWidth =
      cellWidth * this.props.items.length - this.state.screenWidth
    if (direction === DIRECTIONS.RIGHT) {
      this.currentScrollLeft += carouselWidth * SCROLL_PERCENT
      if (this.currentScrollLeft > maxWidth) {
        this.currentScrollLeft = maxWidth
      }
    }

    this.gridRef?.scrollToPosition({
      scrollTop: 1,
      scrollLeft: this.currentScrollLeft,
    })

    /* trigger callback to parent */
    this.props.onScrollCallback()

    if (!this._animationStartTime) {
      this._scrollLeftFinal = this.currentScrollLeft
      this._animationStartTime = performance.now()
      this.animate()
    }
  }

  handleScroll = ({ scrollLeft }) => {
    this.currentScrollLeft = scrollLeft
  }

  animate = () => {
    requestAnimationFrame(() => {
      this._scrollLeftInitial = this._scrollLeftInitial || 0

      const now = performance.now()
      const elapsed = now - this._animationStartTime
      const scrollDelta = this._scrollLeftFinal - this._scrollLeftInitial
      const easedTime = Math.min(1, elapsed / SCROLL_DURATION_MS)
      const scrollLeft = this._scrollLeftInitial + scrollDelta * easedTime

      this.setState({ scrollLeft })

      if (elapsed < SCROLL_DURATION_MS) {
        this.animate()
      } else {
        this._animationStartTime = undefined
        this._scrollLeftInitial = this._scrollLeftFinal
      }
    })
  }

  loadCarousel = (staticHeight, staticWidth) => {
    const { items } = this.props
    const { screenWidth, scrollLeft } = this.state

    if (!items || items.length === 0) {
      return null
    }

    const { currentScrollLeft } = this.state
    const itemCount = items.length

    const maxScreenWidth = staticWidth * items.length

    const maxWidth = maxScreenWidth - screenWidth

    return (
      <StyledCarouselWrapper
        data-testid="virtual-carousel"
        currentScrollLeft={currentScrollLeft}
        ref={node => (this.carouselWrapper = node)}
      >
        {!!scrollLeft && (
          <ArrowContainer direction="left" withBanner={this.props.withBanner}>
            <VirtualArrow
              direction="left"
              testId="virtual-carousel-left-arrow"
              clickFunction={() => this.handleClickDirection(DIRECTIONS.LEFT)}
            />
          </ArrowContainer>
        )}
        <AutoSizer disableHeight>
          {({ width }) => {
            //when users change screen size continuously
            //which has happened from horizontal tablet to vertical tablet,
            //it will trigger `this.setState` constantly that causes exceeded maximum update depth issue
            //we need to have a timer to avoid this problem
            this.widthSettingTimer &&
              clearTimeout &&
              clearTimeout(this.widthSettingTimer)
            this.widthSettingTimer = setTimeout(() => {
              if (screenWidth !== width) {
                this.setState({ screenWidth: width })
              }
            }, WIDTH_SETTING_TIMEOUT_MS)
            return (
              <Grid
                autoHeight
                height={staticHeight}
                columnCount={itemCount}
                columnWidth={staticWidth}
                rowCount={1}
                rowHeight={staticHeight}
                cellRenderer={this.cellRenderer}
                width={width}
                overscanRowCount={2}
                ref={node => (this.gridRef = node)}
                onScroll={this.handleScroll}
                isScrollingOptOut={true}
                scrollLeft={scrollLeft}
              />
            )
          }}
        </AutoSizer>
        {screenWidth <= maxScreenWidth && scrollLeft < maxWidth && (
          <ArrowContainer direction="right" withBanner={this.props.withBanner}>
            <VirtualArrow
              direction="right"
              testId="virtual-carousel-right-arrow"
              clickFunction={() => this.handleClickDirection(DIRECTIONS.RIGHT)}
            />
          </ArrowContainer>
        )}
      </StyledCarouselWrapper>
    )
  }

  render() {
    const {
      items,
      children,
      preloadedItemQuantity,
      staticHeight,
      staticWidth,
    } = this.props

    if (!items || items.length === 0) {
      return null
    }

    const { isClientSide } = this.state

    if (!isClientSide) {
      return (
        <ServerDataContainer>
          {items.slice(0, preloadedItemQuantity).map((item, index) => (
            <div key={index}>{children({ item, index })}</div>
          ))}
        </ServerDataContainer>
      )
    }

    //`staticHeight` is required for this component
    if (!staticHeight) {
      throw new Error(
        '`staticHeight` needs to be passed down in `VirtualCarousel`'
      )
    }

    //`staticWidth` is required for this component
    if (!staticWidth) {
      throw new Error(
        '`staticWidth` needs to be passed down in `VirtualCarousel`'
      )
    }

    //if `staticHeight` is an object, we will match screen sizes (keys) with heights (values) for each carousel
    //`staticWidth` keys should be matched with `staticHeight` keys
    if (typeof staticHeight === 'object') {
      return (
        <React.Fragment>
          {Object.keys(staticHeight).map(key => (
            <Media key={key} query={key}>
              {this.loadCarousel(staticHeight[key], staticWidth[key])}
            </Media>
          ))}
        </React.Fragment>
      )
    }

    //otherwise, `staticHeight` should be a number which is applied for all screen sizes
    return this.loadCarousel(staticHeight)
  }
}

VirtualCarousel.defaultProps = {
  preloadedItemQuantity: DEFAULT_PRELOADED_ITEM_QUANTITY,
  onScrollCallback: () => {},
}

export default VirtualCarousel
