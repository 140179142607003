import defaultTheme from '@ntuctech/devex-tangram/Theme/defaultTheme'
import React from 'react'

const SvgAddToCart = ({ title, disabled, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" {...props}>
    <title>{title}</title>
    <g fill="none">
      <circle
        cx="20"
        cy="20"
        r="20"
        fill={disabled ? '#eaeaea' : defaultTheme.ColorBaseBrandPrimary500}
      />
      <g fill={disabled ? '#666666' : '#FFF'} transform="translate(12 12)">
        <rect width="2" height="16" x="7" rx="1" transform="rotate(90 8 8)" />
        <rect width="2" height="16" x="7" rx="1" />
      </g>
    </g>
  </svg>
)

export default SvgAddToCart
