import React from 'react'
import styled from 'styled-components'
import SvgDeliveryVan from '@ntuctech/devex-tangram/Icon/SvgDeliveryVan'

const Container = styled.div`
  height: 1.25rem;
  text-align: left;
`

const Label = styled.div`
  background-color: #f2f2f2;
  color: #666666;
  border-radius: 0.25rem;
  padding: 0.125rem 0.25rem;
  display: inline-flex;
  align-items: center;
  font-size: 0.6875rem;
  font-weight: 700;
  line-height: 1rem;
`

const Icon = styled.div`
  height: 1rem;
  width: 1rem;
  margin-right: 0.125rem;
`

const MarketplaceItemLabelList = ({ deltime, itemIsOutOfStock }) => {
  return (
    <Container>
      {!itemIsOutOfStock && deltime && (
        <Label>
          <Icon>
            <SvgDeliveryVan size="s" color="#666666" />
          </Icon>
          <span>{`By ${deltime}`}</span>
        </Label>
      )}
    </Container>
  )
}

export default MarketplaceItemLabelList
