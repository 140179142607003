import React, { useState } from 'react'
import styled from 'styled-components'
import { getConfigFor } from '../../utils/configService'
import { SPLIT_FEATURES } from '../../utils/constants'

export const CampaignLabelContainer = styled.div`
  img {
    float: left;
    height: 1rem;
    margin-top: 0.15rem;
  }

  ${({ layoutTypeVoucher }) =>
    layoutTypeVoucher === 'swimlane' &&
    `
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`

const CampaignLabel = styled.img`
  margin-right: ${props => {
    return props.isDetail ? '0.25rem' : '0.125rem'
  }};
  object-fit: contain;
  height: 1rem;
`

function getProductCampaignEnabledSplit() {
  const { isEnabled, configuration } = getConfigFor(
    SPLIT_FEATURES.PRODUCT_CAMPAIGN_LABEL_ENABLED
  )

  return { isEnabled, configuration }
}

const ProductCampaignLabel = ({
  tagIds,
  isDetail,
  campaignLabelImage = '',
}) => {
  const [imageError, setImageError] = useState(false)
  let imageUrl

  const handleImageError = () => {
    setImageError(true)
  }

  const { isEnabled, configuration } = getProductCampaignEnabledSplit()

  const configurationTagIds = (configuration?.tags || []).map(tag => tag.tagId)

  const targetTagId = (tagIds || []).find(tagId =>
    configurationTagIds.includes(tagId)
  )

  const targetTag = (configuration?.tags || []).find(
    tag => tag?.tagId === targetTagId
  )

  // if flag off and campaignLabelImage is present
  if (!isEnabled && campaignLabelImage) {
    imageUrl = campaignLabelImage
  }
  // if flag off and no campaignLabelImage available
  else if (
    !isEnabled ||
    !targetTag?.imageUrl ||
    (imageError && !campaignLabelImage)
  ) {
    return null
  }
  // when Flag is on then look for split image url
  else {
    imageUrl = targetTag?.imageUrl
  }

  return (
    <CampaignLabel
      isDetail={isDetail}
      alt="campaign label"
      src={imageUrl}
      onError={handleImageError}
    />
  )
}

export default ProductCampaignLabel
