import React from 'react'
import dynamic from 'next/dynamic'
import EmptyStarIcon from '../../components/icons/EmptyStarIcon'
import FullStarIcon from '../../components/icons/FullStarIcon'
import HalfStarIcon from '../../components/icons/HalfStarIcon'

import { breakpoints } from '../../lib/Media'
import s from './styles'
const RatingsTooltip = dynamic(() => import('./RatingsTooltip'))

const renderStars = ({ ratingAverage, ratingsRange, isEmptyStarsFilled }) => {
  const starDistribution = String(ratingAverage).split('.')
  let fullStarsCnt = parseInt(starDistribution[0]) || 0
  let halfStarsCnt = 0
  if (starDistribution.length === 2 && fullStarsCnt < 5) {
    if (
      parseInt(starDistribution[1]) >= 3 &&
      parseInt(starDistribution[1]) <= 7
    ) {
      halfStarsCnt = 1
    } else if (parseInt(starDistribution[1]) >= 8) {
      fullStarsCnt += 1
    }
  }
  const emptyStarsCnt = parseInt(ratingsRange) - fullStarsCnt - halfStarsCnt

  let fullStarsHtml = ''
  let emptyStarsHtml = ''
  const halfStarHtml = halfStarsCnt ? (
    <HalfStarIcon data-testid="raings-half-star" width={13} height={13} />
  ) : (
    ''
  )

  if (fullStarsCnt) {
    const fullStarsArr = Array.from(Array(fullStarsCnt).keys())
    fullStarsHtml = fullStarsArr.map(index => {
      return (
        <FullStarIcon
          data-testid="raings-full-star"
          key={index}
          width={13}
          height={13}
        />
      )
    })
  }
  if (emptyStarsCnt) {
    const emptyStarsArr = Array.from(Array(emptyStarsCnt).keys())
    emptyStarsHtml = emptyStarsArr.map(index => {
      if (isEmptyStarsFilled) {
        return (
          <FullStarIcon
            data-testid="raings-empty-star"
            key={`star-${index}`}
            width={13}
            height={13}
            fill="#EAEAEA"
          />
        )
      }
      return (
        <EmptyStarIcon
          data-testid="raings-empty-star"
          key={`star-${index}`}
          width={13}
          height={13}
        />
      )
    })
  }
  return (
    <s.StyledStars>
      {fullStarsHtml}
      {halfStarHtml}
      {emptyStarsHtml}
    </s.StyledStars>
  )
}

const RatingStars = props => {
  const {
    isplp,
    average,
    ratingsconfig,
    displayratings,
    ratingaverage,
    distribution,
    ratingsrange,
    clientItemId,
    total,
    isTruncated,
    wrapper: Wrapper,
    renderTrailingComponent: TrailingReviewRating,
    isEmptyStarsFilled,
    layoutTypeVoucher,
  } = props
  const [showRatingTooltip, setShowRatingTooltip] = React.useState(false)
  const MainWrapper = Wrapper
    ? Wrapper
    : isplp
    ? s.StyledPlpWrapper
    : s.StyledPdpWrapper
  const displayCountPlp =
    isplp && ratingsconfig?.isPlpCountEnabled ? `(${displayratings})` : ''
  const displayCountPdp =
    !isplp && ratingsconfig?.isPdpCountEnabled ? true : false
  const minCountToShowRatings = ratingsconfig?.minCountToShowRatings || 4
  const showRating =
    (isplp &&
      ratingsconfig?.isFeatureEnabled &&
      ratingsconfig?.isPlpStarsEnabled &&
      average > 0 &&
      total >= parseInt(minCountToShowRatings)) ||
    (!isplp && average !== undefined)
  const handleGoToReviewSection = () => {
    // 100 to offset the fixed nav
    const element = document.getElementById('fpBVContainer')
    if (element) {
      const targetDiv = element.offsetTop - 100
      if (targetDiv & (window.innerWidth < breakpoints.tablet)) {
        window.scrollTo({ top: targetDiv, behavior: 'smooth' })
      }
    }
  }

  const processClick = showTooltip => {
    if (window.innerWidth >= breakpoints.tablet) {
      setShowRatingTooltip(showTooltip)
    }
  }
  return (
    <MainWrapper
      data-testid="ratings-star-container"
      ishoveron={!isplp}
      onMouseLeave={() => {
        processClick(false)
      }}
      onClick={handleGoToReviewSection}
      onMouseEnter={() => {
        if (!isplp) {
          processClick(true)
        }
      }}
      style={layoutTypeVoucher === 'horizontal' ? { paddingLeft: '0' } : {}}
    >
      {showRating && (
        <s.StyledRatings
          aria-label="Ratings"
          data-testid="ratings-star-wrapper"
        >
          {isTruncated && (
            <s.StyledStars>
              <FullStarIcon
                data-testid="raings-full-star"
                width={13}
                height={13}
              />
            </s.StyledStars>
          )}
          {!TrailingReviewRating &&
            total >= parseInt(minCountToShowRatings) && (
              <s.StyledAverage
                aria-label={`Average rating is ${ratingaverage}`}
                className={isplp ? null : 'pdp'}
              >
                {ratingaverage}
              </s.StyledAverage>
            )}

          {!isTruncated &&
            renderStars({
              ratingAverage: ratingaverage,
              ratingsRange: ratingsrange,
              isEmptyStarsFilled,
            })}
          {displayCountPlp && (
            <s.StyledRatingCount>{displayCountPlp}</s.StyledRatingCount>
          )}
          {displayCountPdp && (
            <s.StyledRatingCount>
              <s.StyledSeparator>|</s.StyledSeparator>
              {total < parseInt(minCountToShowRatings) ? (
                <span>Gathering more reviews</span>
              ) : (
                <span>{`${displayratings} ratings`}</span>
              )}
            </s.StyledRatingCount>
          )}
          {TrailingReviewRating && <TrailingReviewRating />}
        </s.StyledRatings>
      )}
      {showRatingTooltip && average > 0 && (
        <RatingsTooltip
          arrowTop="1.1rem"
          arrowLeft="2rem"
          tooltipLeft="0"
          distribution={distribution}
          displayratings={displayratings}
          ratingaverage={ratingaverage}
          clientItemId={clientItemId}
          ratingsConfig={ratingsconfig}
          setShowRatingTooltip={setShowRatingTooltip}
        />
      )}
    </MainWrapper>
  )
}

RatingStars.defaultProps = {
  isEmptyStarsFilled: true,
}

export default RatingStars
