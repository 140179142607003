import * as React from 'react'

function SvgEmptyStar(props) {
  return (
    <svg
      width={12}
      height={12}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.492 18.183l2.179-1.19.008-.004c.036-.02.127-.07.22-.114a2.559 2.559 0 011.61-.198c.265.053.47.14.592.198.093.044.184.094.22.114l.008.004 2.18 1.19-.436-2.764-.001-.008c-.006-.037-.021-.132-.03-.227a2.561 2.561 0 01.286-1.474c.117-.219.244-.378.327-.474.062-.072.13-.141.155-.168l.006-.006 1.992-2.068-2.64-.42-.01-.001c-.038-.007-.138-.022-.235-.043a2.567 2.567 0 01-1.365-.788 2.563 2.563 0 01-.349-.494c-.047-.088-.09-.18-.107-.215l-.004-.008L12 6.716l-1.098 2.309-.004.008c-.017.036-.06.128-.107.215a2.563 2.563 0 01-1.14 1.087 2.566 2.566 0 01-.574.195c-.097.02-.197.037-.236.043a.225.225 0 01-.009.001l-2.64.42 1.992 2.068.006.006c.026.027.093.097.155.168a2.562 2.562 0 01.61 1.373c.03.246.017.45.004.575-.01.095-.025.19-.031.227l-.001.008-.435 2.764zM6.58 21.758c-.316.173-.474.26-.593.24a.345.345 0 01-.243-.172c-.058-.105-.03-.283.026-.64l.961-6.112c.016-.1.024-.15.018-.197a.346.346 0 00-.038-.122.753.753 0 00-.128-.151l-4.21-4.37c-.238-.248-.358-.372-.37-.489a.346.346 0 01.092-.277c.08-.085.25-.112.589-.166l5.799-.923c.105-.016.158-.025.203-.047a.345.345 0 00.107-.08.757.757 0 00.102-.181l2.606-5.48c.16-.336.24-.504.349-.556a.345.345 0 01.3 0c.11.052.19.22.349.556l2.606 5.48a.756.756 0 00.102.182c.03.033.066.06.107.08.045.021.098.03.203.046l5.8.923c.338.054.508.081.588.166.07.074.104.176.093.277-.014.117-.133.24-.37.488l-4.211 4.37c-.07.073-.105.11-.128.152a.345.345 0 00-.038.121.753.753 0 00.018.198l.961 6.112c.056.357.084.535.026.64a.345.345 0 01-.244.171c-.118.02-.276-.066-.592-.239l-5.155-2.814c-.097-.053-.145-.08-.197-.09a.343.343 0 00-.136 0c-.052.01-.1.037-.197.09L6.58 21.758z"
        fill="#666"
      />
    </svg>
  )
}

export default SvgEmptyStar
