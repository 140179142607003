import defaultTheme from '@ntuctech/devex-tangram/Theme/defaultTheme'
import React from 'react'

const SvgRemoveFromCart = ({ title, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" {...props}>
    <title>{title}</title>
    <g fill="none">
      <circle
        cx="20"
        cy="20"
        r="20"
        fill={defaultTheme.ColorBaseBrandPrimary500}
      />
      <rect
        width="2"
        height="16"
        x="19"
        y="12"
        fill="#FFF"
        rx="1"
        transform="rotate(90 20 20)"
      />
    </g>
  </svg>
)

export default SvgRemoveFromCart
