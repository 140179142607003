import styled from 'styled-components'
import defaultTheme from '@ntuctech/devex-tangram/Theme/defaultTheme'
import { SCREEN_SIZE } from '../../lib/Media'

const styles = {}

styles.StyledPlpWrapper = styled.div`
  position: relative;
  min-height: 1rem;
  padding: 0 0.5rem;
  text-align: left;

  ${SCREEN_SIZE.From.Tablet} {
    margin: 0.5rem 0;
  }

  ${SCREEN_SIZE.Below.Mobile} {
    margin: 0.375rem 0;
    padding: 0 0.5rem;
  }
`

styles.StyledPdpWrapper = styled.div`
  position: relative;
  margin-bottom: 0 !important;
  padding-bottom: 1.25rem;
  display: inline-block;
  cursor: ${props => (props.ishoveron ? 'pointer' : 'default')};
`

styles.StyledRatings = styled.div`
  display: flex;
  font-size: 0.75rem;
  line-height: 0.86rem;
  font-weight: normal;
  color: ${defaultTheme.ColorBaseMineShaft500};
`
styles.StyledSeparator = styled.span`
  color: #eaeaea;
  padding-right: 0.25rem;
`

styles.StyledAverage = styled.span`
  display: inline-block;
  margin: 0 0.25rem 0 0;
  &.pdp {
    color: ${defaultTheme.ColorBaseBrandPrimary500};
    font-weight: bold;
  }
  ${SCREEN_SIZE.Below.Mobile} {
    &.pdp {
      color: ${defaultTheme.ColorBaseMineShaft500};
      font-weight: normal;
    }
  }
`

styles.StyledStars = styled.span`
  display: inline-block;
  display: flex;
  align-items: center;
  margin: 0 0.125rem 0 0;

  img {
    vertical-align: top;
    margin: 0 0.125rem 0 0;
    display: inline;
  }
`

styles.StyledRatingCount = styled.span`
  display: inline-block;
`

export default styles
