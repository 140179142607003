import * as React from 'react'

function SvgHalfStar(props) {
  return (
    <svg
      width={12}
      height={12}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.265 18.944c-.097-.053-.145-.08-.197-.09a.343.343 0 00-.136 0c-.052.01-.1.037-.197.09L6.58 21.758c-.316.173-.474.26-.593.24a.345.345 0 01-.243-.172c-.058-.105-.03-.283.026-.64l.961-6.112c.016-.1.024-.15.018-.197a.346.346 0 00-.038-.122c-.023-.042-.058-.079-.128-.151l-4.21-4.37c-.238-.248-.358-.372-.37-.489a.346.346 0 01.092-.277c.08-.085.25-.112.589-.166l5.799-.923a.755.755 0 00.203-.047.345.345 0 00.107-.08.757.757 0 00.102-.181l2.606-5.48c.16-.336.24-.504.349-.556a.345.345 0 01.3 0c.11.052.19.22.349.556l2.606 5.48a.756.756 0 00.102.182c.03.033.066.06.107.08.045.021.098.03.203.046l5.8.923c.338.054.508.081.588.166.07.074.104.176.093.277-.014.117-.133.24-.37.488l-4.211 4.37c-.07.073-.105.11-.128.152a.345.345 0 00-.038.121.753.753 0 00.018.198l.961 6.112c.056.357.084.535.026.64a.345.345 0 01-.244.171c-.118.02-.276-.066-.592-.239l-5.155-2.814z"
        fill="#EAEAEA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 18.848V2a.345.345 0 00-.15.035c-.11.052-.19.22-.349.556L8.895 8.07a.757.757 0 01-.102.182.345.345 0 01-.107.08.755.755 0 01-.203.046l-5.8.923c-.338.054-.508.081-.588.166a.346.346 0 00-.093.277c.013.117.133.24.37.488l4.211 4.37c.07.073.105.11.128.152.02.038.033.08.038.121a.753.753 0 01-.018.198l-.961 6.112c-.056.357-.084.535-.026.64.051.09.14.154.244.171.118.02.276-.066.592-.239l5.155-2.814c.097-.053.145-.08.197-.09a.343.343 0 01.068-.006z"
        fill="#FFB60F"
      />
    </svg>
  )
}

export default SvgHalfStar
