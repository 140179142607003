function DietarySelector(metaData) {
  const requiredAttributes = {
    Halal: true,
    Organic: true,
  }
  const DietaryAttributes =
    (metaData &&
      Array.isArray(metaData['Dietary Attributes']) &&
      metaData['Dietary Attributes'].filter(
        name => requiredAttributes[name]
      )) ||
    []
  return DietaryAttributes
}

export default DietarySelector
