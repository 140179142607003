import styled from 'styled-components'
import defaultTheme from '@ntuctech/devex-tangram/Theme/defaultTheme'
import NoImageIcon from '../icons/NoImg'
import { SCREEN_SIZE } from '../../lib/Media'
import Text from './../Text'

export const RecommendedProductWrapper = styled.div`
  position: relative;
  margin: 0
    ${({ layoutTypeVoucher }) =>
      layoutTypeVoucher === 'horizontal' ? '0' : '1rem'}
    0.5rem 0;
  ${({ layoutTypeVoucher }) =>
    layoutTypeVoucher === 'swimlane' && 'margin-right: 0.5rem; height: 100%;'}

  &:hover {
    box-shadow: 2px 2px 6px 0 rgba(105, 105, 105, 0.2);
    border-radius: 4px;
  }
`
export const StyledContainer = styled.div`
  position: relative;
  display: grid;
  ${({ layoutTypeVoucher }) =>
    layoutTypeVoucher === 'horizontal'
      ? `grid-template-columns: 5.5rem 1fr;`
      : `grid-template-rows: 12.5rem 1fr;`}

  /* display: block; */
  background-color: white;
  border-radius: 0.25rem;
  height: 100%;
  line-height: 1rem;

  ${SCREEN_SIZE.Below.MobileL} {
    ${({ layoutTypeVoucher }) =>
      layoutTypeVoucher === 'horizontal'
        ? `grid-template-columns: 5.5rem 1fr;`
        : `grid-template-rows: 9.25rem 1fr;`}
  }
`

export const StyledCartContainer = styled.div`
  display: block;
  background-color: white;
`

export const StyledA = styled.a`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  flex-shrink: 0;
  color: currentColor;
  object-fit: contain;
  overflow: hidden;
  width: 100%;
  height: 100%;
  ${StyledContainer} {
    border: ${({ inCart }) =>
      inCart
        ? `1px solid ${defaultTheme.ColorBaseBrandPrimary500}`
        : `1px solid ${defaultTheme.ColorBaseMineShaft80}`};
  }
  &:hover {
    color: currentcolor;
    text-decoration: none;
  }
`

export const ImageContainer = styled.div`
  display: flex;
  ${({ shelfLifeImageSrc }) => shelfLifeImageSrc && `position: relative;`}
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  height: 100%;
  width: 100%;
  position: relative;
`

export const PaddingBox = styled.div`
  padding: 0.25rem 0.5rem 0;
  ${SCREEN_SIZE.Only.Mobile} {
    height: 9.25rem;
  }
  ${SCREEN_SIZE.Only.MobileL} {
    height: 9.25rem;
  }
  ${SCREEN_SIZE.From.Tablet} {
    ${({ layoutTypeVoucher }) =>
      layoutTypeVoucher === 'horizontal'
        ? `height: 5.5rem; margin-top: 1rem; width: auto;`
        : `height: 12.5rem;`}
    padding: 0.5rem;
  }
`

export const StyledNoImageIcon = styled(NoImageIcon)`
  margin: auto;
  width: 50%;
  ${SCREEN_SIZE.Only.Mobile} {
    height: 10rem;
  }
  ${SCREEN_SIZE.Only.MobileL} {
    height: 10rem;
  }
  ${SCREEN_SIZE.From.Tablet} {
    height: 12.5rem;
  }
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-shrink: 0;
`
export const StyledTitle = styled.div`
  text-align: left;
  overflow: hidden;
  flex-shrink: 0;

  ${({ layoutTypeVoucher }) =>
    layoutTypeVoucher !== 'horizontal' && `height: 4.75rem;`}

  ${SCREEN_SIZE.From.Tablet} {
    ${({ layoutTypeVoucher }) =>
      layoutTypeVoucher !== 'horizontal' && `height: 3.625rem;`}
  }
`

export const StyledInfo = styled.div`
  position: relative;
  flex-shrink: 0;
  padding: 0 0.5rem;
  ${({ layoutTypeVoucher }) =>
    layoutTypeVoucher === 'horizontal' && `padding-left: 0rem;`}

  ${SCREEN_SIZE.From.Tablet} {
    ${({ layoutTypeVoucher }) =>
      layoutTypeVoucher !== 'horizontal' && `height: 5.125rem;`}
    width: 100%;
  }
  ${SCREEN_SIZE.Only.Mobile} {
    width: 100%;
  }
`

export const StyledSaveButton = styled.button`
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
  color: #1557bf;
  cursor: pointer;
  line-height: 0;
`

export const Div = styled.div`
  display: flex;
  align-items: center;
  > span:not(:first-child) {
    margin-left: 0.25rem;
  }
`

export const PriceAndSaveButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 1.5rem;
`
export const PlainDiv = styled.div`
  display: block;
  position: relative;
`

export const Seperator = styled.span`
  margin: 0 2px;
`
export const StyledList = styled.div`
  display: flex;
  white-space: nowrap;
`

export const OfferCard = styled.div`
  border: solid 1px #ffc499;
  border-radius: 2px;
  padding: 0.2rem;
  width: max-content;
  max-width: 100%;
  :not(:last-of-type) {
    margin-bottom: 0.25rem;
  }
  overflow: hidden;
`

export const OfferText = styled.div`
  flex: 1;
  width: 100%;
  color: ${props => props.color || defaultTheme.ColorBaseBrandSecondary500};
  text-align: left;
  font-size: 0.75rem;
  line-height: 1rem;
  .inline {
    display: inline-block;
  }
  strong {
    font-weight: 700;
  }
`

export const OfferCardContainer = styled.div`
  padding: 0 0.5rem;
  height: 4.875rem;
  margin: 0 0 0.25rem 0;

  ${SCREEN_SIZE.From.Tablet} {
    height: 4.75rem;
  }
`

const borderRadiusSet = {
  single: '3px 0',
  first: '3px 0 0 0',
  last: '0 0 3px 0',
  rightEnd: '0 3px',
}

export const PwpFlag = styled(Text)`
  display: inline-flex;
  margin: ${props => (props.noMargin ? '0' : '0 0.125rem 0 0')};
  padding: 0.125rem;
  background: ${props =>
    props.bgcolour
      ? `${props.bgcolour}`
      : defaultTheme.ColorBaseBrandSecondary500};
  border-radius: ${props =>
    props.borderstyle
      ? borderRadiusSet[props.borderstyle]
      : borderRadiusSet.single};
  ${({ borderstyle }) =>
    borderstyle === 'rightEnd' &&
    `
    margin: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  `}
`

export const StyledTagWrapper = styled.div`
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  text-align: left;
`

export const StyledImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`

export const StyledJwcPlpIcon = styled.img`
  display: flex;
  text-align: left;
  margin: ${props =>
    props.rightMargin
      ? '0 0.0625rem 0 0'
      : props.noMargin
      ? '0'
      : '0 0 0.25rem'};
  background-color: ${props => (props.bgcolour ? props.bgcolour : '#FFF')};
`

export const LPIcon = styled.img`
  margin-left: ${props => props.left};
  margin-right: ${props => props.right};
  margin-bottom: ${props => props.bottom};
  width: ${props => props.width};
`

export const DCPlpOffer = styled(Text)`
  line-height: 0.75rem;
  margin-right: 2px;
`
export const StyledAdText = styled(Text)`
  height: 1.25rem;
  width: 1.5rem;
  background-color: #f2f2f2;
  margin-right: 0.25rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  display: inline-block;
  text-align: center;
  color: #333;
  font-weight: bold;
`
export const ShelfLifeImage = styled.img`
  position: absolute;
  bottom: 8px;
  right: 0;
  height: 3rem;
  width: 3rem;
  z-index: 1;
  ${SCREEN_SIZE.From.Tablet} {
    bottom: 0.75rem;
    right: 0.25rem;
    height: 3.75rem;
    width: 3.75rem;
  }
`

export const MarketplaceItemLabelListContainer = styled.div`
  margin-bottom: 0.375rem;
  margin-left: 0.5rem;
  ${SCREEN_SIZE.From.MobileL} {
    margin-bottom: 0.5rem;
  }
`
export const PromoLabelWraper = styled.div`
  display: flex;
  position: relative;
`
export const StyledLabel = styled.span`
  padding: 0 0.125rem;
  font-weight: 700;
`
export const StyledDot = styled.span`
  height: 0.3125rem;
  width: 0.3125rem;
  background-color: ${defaultTheme.ColorBaseBrandSecondary500};
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0.125rem;
`
export const StyledPastPurchase = styled(Text)`
  padding: 0 0.5rem 0.5rem;
  text-align: left;
  background: #fff;
  min-width: 9rem;
`

export const MetaDataContainer = styled.div`
  display: block;
  background-color: white;
  border: 'none';
  outline: 'none';
  margin-top: 0.125rem;
`

export const ItemNameAndAddToCart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: ${({ hasOfferLabel, layoutTypeVoucher }) =>
    layoutTypeVoucher === 'horizontal'
      ? hasOfferLabel
        ? `1.25rem`
        : `0.75rem`
      : '0rem'};
  ${({ layoutTypeVoucher }) =>
    layoutTypeVoucher === 'horizontal' &&
    `padding-right: 0.25rem; padding-bottom: 0.75rem;`}
`
