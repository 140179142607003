import {
  fetchCategory,
  getNCheckFn as getNCheck,
  GTM_ENV_VAR as GEV,
  PAGE_TYPES,
} from '../../lib/eventTrackerFunction'
import { OUT_OF_STOCK, IN_STOCK } from '../../lib/getInventoryStatus'
import getPwpOffer, { filterSimplePromotion } from '../../lib/offer'
import { isOutOfStock } from '../../lib/getInventoryStatus'
import compact from 'lodash/compact'
import { AMP_EVENT_NAME as AEN, getPageTypeByUrl } from '../../lib/amplitude'
import { getCtaLocationMeta, getPreviousCtaLocationMeta } from '../../lib/utils'
import { isCitrusProductTrackingEnabled } from '../../lib/trackCitrus'
import { objectValues } from '../../lib/polyfills'
import { isBoughtBefore } from '../../utils/Utils'

/* istanbul ignore next */
const productDetailsCardEvent = ({
  eventAction,
  eventCat,
  value,
  details,
  storeSpecific,
  pageType,
  itemIsOutOfStock,
  productPosition,
  loc,
  track,
  assetType,
  recipeTitle,
  amplitudeEventName,
  ctaSublocation,
  ctaLocation,
  delta,
  checkoutAddress,
  remoteConfig,
}) => {
  const productInfo = details
  const statistics = details?.reviews?.statistics || {}
  const categoryList = fetchCategory(productInfo.primaryCategory)
  const offerPrice = (storeSpecific.mrp - storeSpecific.discount).toFixed(2)
  const offersToTrack = details.offers && details.offers.slice(0, 2)
  const promoText =
    offersToTrack && offersToTrack.map(offer => offer.description).join('|')
  let pageTypeToTrack = ''
  pageTypeToTrack = PAGE_TYPES.LISTING_DETAIL
  const ecommerceData = getAddAndRemoveFromCartEcommerceEvent({
    productPosition,
    productInfo,
    eventAction,
    categoryList,
    storeSpecific,
    itemIsOutOfStock,
    remoteConfig,
    checkoutAddress,
    loc,
    statistics,
  })

  const isProductBoughtBefore =
    details?.pastPurchases && isBoughtBefore(details?.pastPurchases)
      ? 'yes'
      : 'no'

  const event = {
    [GEV.AMPLITUDE_IS_PAST_PURCHASE]: isProductBoughtBefore,
    [GEV.EVENT_CAT]: eventCat,
    [GEV.EVENT_ACTION]: eventAction,
    [GEV.PROD_ID]: getNCheck(productInfo, 'clientItemId', ''),
    [GEV.PRODUCT_ID_ZOP]: getNCheck(productInfo, 'id', ''),
    [GEV.PROD_NAME]: getNCheck(productInfo, 'name', ''),
    [GEV.PROD_CUR]: getNCheck(storeSpecific, 'currency.name', ''),
    [GEV.PROD_QUANT]: value ? value.toString() : '0',
    [GEV.PROD_BRAND]: getNCheck(productInfo, 'brand.name', ''),
    [GEV.RETAIL_PRICE]: parseFloat(getNCheck(storeSpecific, 'mrp', ''))
      .toFixed(2)
      .toString(),
    [GEV.OFFER_PRICE]: offerPrice ? offerPrice.toString() : '',
    [GEV.PROD_CAT]: 'Retail',
    [GEV.INVTRY_COUNT]: getNCheck(storeSpecific, 'stock', ''),
    [GEV.PAGE_TYPE]: pageType ? pageType : pageTypeToTrack,
    [GEV.PROMO_TEXT]: promoText,
    [GEV.INVENTORY_STATUS]: itemIsOutOfStock ? OUT_OF_STOCK : IN_STOCK,
    [GEV.DIMENSION97]: '',
    [GEV.ASSET_TYPE]: assetType,
    [GEV.RECIPE_TITLE]: recipeTitle,
    [GEV.AMPLITUDE_EVENT_NAME]: amplitudeEventName,
    [GEV.PRODUCT_RATING]: productInfo?.reviews?.statistics?.average,
    [GEV.PROMOTION_COUNT]: productInfo?.offers?.length || 0,
    [GEV.PROMOTION_TYPES]: compact(
      productInfo?.offers?.map(offer => offer?.metaData?.sapReference) || []
    ),
    [GEV.PROMOTION_DESCRIPTIONS]: compact(
      productInfo?.offers?.map(offer => offer.description) || []
    ),
    [GEV.AMPLITUDE_CTA_SUBLOCATION]: ctaSublocation,
    [GEV.AMPLITUDE_CTA_LOCATION]: ctaLocation,
    [GEV.AMPLITUDE_CTA_LOCATION]: {
      pageName: getPageTypeByUrl(),
      pageMeta: getCtaLocationMeta() || [],
    },
    [GEV.AMPLITUDE_2CLICK_CALL_TO_ACTION_LOCATION]: {
      pageName: getPageTypeByUrl(sessionStorage.getItem('previousRoute')),
      pageMeta: getPreviousCtaLocationMeta(),
    },
    [GEV.AMPLITUDE_PRODUCT_QUANTITY]: delta,
    ...ecommerceData,
  }
  if (productPosition) {
    event[[GEV.PRODUCT_POSITION]] = productPosition
  }

  if (eventAction === 'AddToCart') {
    event[[GEV.DIMENSION101]] = statistics?.averageDisplay || ''
    event[[GEV.DIMENSION102]] = statistics?.total || ''
  }

  if (loc) {
    event[[GEV.EVENT_LABEL]] = `Loc=${loc}`
  }
  if (productInfo.metaData && productInfo.metaData['Storage Information']) {
    event[[[GEV.STORAGE]]] = productInfo.metaData['Storage Information']
  }
  const keys = Object.keys(categoryList)
  keys.forEach(itemInfo => {
    event[itemInfo] = categoryList[itemInfo]
  })
  track(event)
}

export const productCartAction = productData => {
  const { delta, ...baseProps } = productData
  const otherProps =
    productData.delta >= 1
      ? {
          eventAction: 'AddToCart',
          eventCat: 'Ecommerce-Engagement',
          amplitudeEventName: AEN.PRODUCT_ADDED_TO_CART,
          delta,
        }
      : {
          eventAction: 'RemoveFromCart',
          eventCat: 'Ecommerce-Engagement',
          amplitudeEventName: AEN.PRODUCT_REMOVED_FROM_CART,
          delta,
        }
  productDetailsCardEvent({
    ...baseProps,
    ...otherProps,
  })
}

export const getProductInfo = ({ countOf, details, checkoutAddress }) => {
  let { bulkOrderThreshold, stockOverride, offers, images, name, slug } =
    details
  let storeSpecificData = []
  if (checkoutAddress && checkoutAddress.storeId) {
    storeSpecificData =
      details.storeSpecificData &&
      details.storeSpecificData.filter(
        productInfo => productInfo.storeId === checkoutAddress.storeId
      )

    if (!storeSpecificData || storeSpecificData.length === 0) {
      storeSpecificData = details.storeSpecificData
    }
  } else {
    storeSpecificData = details.storeSpecificData
  }

  if (storeSpecificData && storeSpecificData.length === 0) {
    storeSpecificData = [
      {
        ...details.storeSpecificData[0],
        stock: 0,
        unlimitedStock: false,
      },
    ]
  }
  const storeBulkOrderThreshold =
    (stockOverride && stockOverride.storeBulkOrderThreshold) || 0
  const unlimitedStock =
    storeSpecificData &&
    storeSpecificData.length > 0 &&
    storeSpecificData[0].unlimitedStock
  const maxPurchasableStock =
    (stockOverride && stockOverride.maxPurchasableStock) || Infinity
  const price = +(
    storeSpecificData &&
    storeSpecificData.length > 0 &&
    storeSpecificData[0].mrp
  )
  let discount =
    storeSpecificData &&
    storeSpecificData.length > 0 &&
    storeSpecificData[0].discount

  const { sp, otherOffers } = offers ? filterSimplePromotion(offers) : {}

  offers = otherOffers

  const { isPwpApplicable, pwpDiscount } = getPwpOffer(offers, price)

  if (pwpDiscount) {
    discount = Number(pwpDiscount)
  }
  const storeSpecific = details.storeSpecificData[0]

  const itemIsOutOfStock = isOutOfStock({
    value: countOf(details),
    stock: storeSpecific.stock,
    unlimitedStock,
    bulkOrderThreshold,
    storeBulkOrderThreshold,
    checkoutAddress,
  })

  return {
    itemIsOutOfStock,
    maxPurchasableStock,
    images,
    name,
    slug,
    discount,
    storeSpecific,
    offers,
    storeSpecificData,
    price,
    bulkOrderThreshold,
    storeBulkOrderThreshold,
    unlimitedStock,
    sp,
    isPwpApplicable,
  }
}

export const getProductDetailViewEcommerceEvent = props => {
  const data = getEcommerceProductsData(props)
  data[0].list = 'product detail page'
  return {
    [GEV.ECOMMERCE]: {
      detail: {
        products: data,
      },
    },
  }
}

export const getAddAndRemoveFromCartEcommerceEvent = ({
  eventAction,
  ...props
}) => {
  if (eventAction !== 'AddToCart' && eventAction !== 'RemoveFromCart') {
    return {}
  }

  const ecommerceKey = eventAction === 'AddToCart' ? 'add' : 'remove'

  return {
    [GEV.ECOMMERCE]: {
      currencyCode: 'SGD',
      [ecommerceKey]: {
        products: getEcommerceProductsData(props),
      },
    },
  }
}

const getEcommerceProductsData = ({
  productPosition,
  productInfo,
  categoryList,
  storeSpecific,
  itemIsOutOfStock,
  remoteConfig,
  checkoutAddress,
  loc,
  statistics,
  details,
  shouldTrackReviews = true,
  ga4ProductListName,
  delta,
}) => {
  let offerTypes = []

  let offerString = ''
  if (productInfo.offers?.length) {
    offerString = productInfo.offers.map(offer => offer.id).join(',')
    offerTypes = productInfo.offers.map(offer => offer.type)
  }

  let offersUnAppliedString = ''
  if (productInfo.offersUnApplied?.length) {
    offersUnAppliedString = productInfo.offersUnApplied
      .map(offer => offer.id)
      .join(',')
    offerTypes = [
      ...offerTypes,
      ...productInfo.offersUnApplied.map(offer => offer.type),
    ]
  }

  let offerTypeString = ''
  if (offerTypes.length) {
    offerTypeString = offerTypes.join(',')
  }

  const isProductBoughtBefore =
    productInfo?.pastPurchases && isBoughtBefore(productInfo?.pastPurchases)
      ? 'yes'
      : 'no'

  const products = [
    {
      [GEV.DIMENSION106]: details?.track || '',
      [GEV.ECOMM_ID]: getNCheck(productInfo, 'clientItemId'),
      [GEV.ECOMM_NAME]: getNCheck(productInfo, 'name', '').toString(),
      [GEV.ECOMM_CATEGORY]: objectValues(categoryList).join('/'),
      [GEV.ECOMM_VARIANT]: getNCheck(
        productInfo,
        'metaData.DisplayUnit',
        ''
      ).toUpperCase(),
      [GEV.ECOMM_BRAND]: getNCheck(productInfo, 'brand.name', '').toString(),
      [GEV.ECOMM_PRICE]: parseFloat(getNCheck(storeSpecific, 'mrp', ''))
        .toFixed(2)
        .toString(),
      [GEV.DIMENSION84]: window.location?.pathname,
      [GEV.ECOMM_DIMENSION32]: getNCheck(storeSpecific, 'stock', '').toString(),
      [GEV.ECOMM_DIMENSION60]: getNCheck(
        checkoutAddress,
        'clientId',
        ''
      ).toString(),
      [GEV.ECOMM_DIMENSION61]: itemIsOutOfStock ? OUT_OF_STOCK : IN_STOCK,
      [GEV.ECOMM_IS_PAST_PURCHASE]: isProductBoughtBefore,
      [GEV.DIMENSION97]:
        isCitrusProductTrackingEnabled(remoteConfig) && productInfo?.adId
          ? `ct_${productInfo?.adId}`
          : '',
      [GEV.DIMENSION89]: offerString,
      [GEV.DIMENSION90]: offersUnAppliedString,
      [GEV.DIMENSION91]: offerTypeString,
      ...(shouldTrackReviews
        ? {
            [GEV.DIMENSION101]: statistics?.averageDisplay || '',
            [GEV.DIMENSION102]: statistics?.total || '',
          }
        : {}),
    },
  ]

  if (loc) {
    products[0][GEV.ECOMM_LIST] = `Loc=${loc}`
  }
  if (ga4ProductListName) {
    products[0][GEV.ECOMM_LIST] = ga4ProductListName
  }

  if (productPosition) {
    products[0][GEV.PRODUCT_POSITION] = productPosition
  }

  if (delta) {
    products[0][GEV.ECOMM_QUANTITY] = Math.abs(delta)
  }

  return products
}

export const getRecipeImageSrcSet = (sizeMap, allowList = []) => {
  if (!sizeMap) {
    return ''
  }
  const sizeList = Object.keys(sizeMap)
  const allowedSizeList = allowList?.length
    ? sizeList.filter(size => allowList.includes(size))
    : sizeList
  return `${allowedSizeList
    .map(size => {
      const imgUrl = sizeMap[size]
      return `${imgUrl} ${size}w`
    })
    .join(', ')}`
}
