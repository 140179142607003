import React from 'react'
import dynamic from 'next/dynamic'
import Counter from './../Counter'
import styled from 'styled-components'
import defaultTheme from '@ntuctech/devex-tangram/Theme/defaultTheme'
import Text from '../Text/Text'
import { SCREEN_SIZE } from '../../lib/Media'
import { CheckoutAddressConsumer } from '../../components/CheckoutAddressProvider'
import { EventTrackingConsumer } from '../../components/EventTrackingProvider/EventTrackingProvider'
import CartIcon from './../icons/Cart'
import { isOutOfStock } from '../../lib/getInventoryStatus'
import GlobalContext from '../GlobalContext'

const AddressPopup = dynamic(() =>
  import('../../components/CheckoutAddressProvider/AddressPopup')
)
const VALUE_TO_SHOW_CART_IMAGE = 0

export const StyledCounter = styled.div`
  display: flex;
  align-items: center;
  ${({ layoutTypeVoucher }) =>
    layoutTypeVoucher === 'horizontal'
      ? 'justify-content: flex-end; padding-right: 0.5rem; width: 100%;'
      : 'justify-content: space-around;'}
  ${({ isVerticalCard }) => isVerticalCard && `width: 100%;`}

  ${({ noStyle }) =>
    !noStyle &&
    `
    ${SCREEN_SIZE.Only.Mobile} {
      background: #ffffff;
      ${({ isVerticalCard }) =>
        isVerticalCard ? `height: 2.5rem;` : `height: 3.5rem;`}
      height: 2.5rem;
      width: 100%;
      margin: 0 auto;
    }
  `}
`

const SmallCartIcon = styled(CartIcon)`
  display: block;
`

const LargeCartIcon = styled(CartIcon)`
  display: none;
`

export const StyledCounterInProductDetail = styled(StyledCounter)`
  ${SCREEN_SIZE.Only.Mobile} {
    background-color: #1557bf;
    width: 90%;
    border-radius: 4rem;
    height: 3.5rem;

    input {
      margin: 0 3rem;
    }
  }
`

const StyledCart = styled.div`
  align-self: flex-end;
  width: 100%;
  ${({ layoutTypeVoucher, isVerticalCard }) =>
    layoutTypeVoucher === 'horizontal'
      ? `
    display: flex;
    justify-content: flex-end;
    `
      : `
    display: flex;
    justify-content: ${isVerticalCard ? 'center' : 'flex-start'};
    `}
  ${SCREEN_SIZE.Only.Mobile} {
    display: flex;
    align-items: center;
    justify-content: ${({ layoutTypeVoucher }) =>
      layoutTypeVoucher === 'horizontal' ? `flex-end` : `center`};
    width: 100%;
  }
`

const StyledText = styled(Text)`
  line-height: 1.1rem;
  font-size: 0.95rem;
  margin: 0;
  font-weight: bolder;
  margin-top: 0.2rem;
`

export const StyledCartButtonInProductDetail = styled.button`
  background-color: #1557bf;
  border: 0;
  padding: 0.5rem;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border-radius: 2rem;
  width: 12rem;
  height: 2.65rem;

  ${SCREEN_SIZE.Only.Mobile} {
    text-align: center;
    width: 90%;
    margin: 0 auto;
    height: 3.5rem;
    border-radius: 4rem;

    ${StyledText} {
      font-size: 1.2rem;
    }

    ${SmallCartIcon} {
      display: none;
    }

    ${LargeCartIcon} {
      display: block;
    }
  }

  > * {
    margin: 0rem 0.35rem;
  }
`

export const StyledCartButton = styled.button`
  background-color: transparent;
  color: ${defaultTheme.ColorBaseBrandPrimary500};
  border: 0;
  padding: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border-radius: 2rem;
  height: ${props => (props.isVerticalCard ? '2.5rem' : '3.3rem')};
  width: fit-content;

  > * {
    margin: 0rem 0.35rem 0 0;
  }

  ${SCREEN_SIZE.From.Tablet} {
    padding: 0.8rem;
  }
`

export const OutOfStockInProductCards = styled.div`
  padding: 0.8rem;
  height: ${props => (props.isVerticalCard ? '2.5rem' : '3.3rem')};
  width: 100%;
  display: flex;
  ${({ layoutTypeVoucher }) =>
    layoutTypeVoucher !== 'horizontal' && 'justify-content: center;'}
  ${({ layoutTypeVoucher }) =>
    layoutTypeVoucher === 'horizontal' &&
    'width: 100%; justify-content: flex-end;'}

  ${Text} {
    font-size: 0.95rem;
    font-weight: bold;
    text-align: center;
    color: #6c6c6c;
    ${({ layoutTypeVoucher }) =>
      layoutTypeVoucher !== 'horizontal' && 'margin: auto;'}
  }
`

export const StyledOutOfStockInProductDetail = styled.div`
  background-color: #696969;
  border: 0;
  padding: 0.5rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border-radius: 2rem;
  width: 12rem;
  height: 2.5rem;
  ${SCREEN_SIZE.Only.Mobile} {
    text-align: center;
    width: 90%;
    margin: 0 auto;
    height: 4.5rem;
    border-radius: 4rem;

    ${Text} {
      font-size: 1.5rem;
    }
  }
  > * {
    margin: 0rem 0.35rem;
  }
`

class CartCounter extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOutOfStockState: false,
    }

    this.updateStockState = this.updateStockState.bind(this)
  }

  componentDidMount() {
    this.updateStockState()
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.checkoutAddress &&
      this.props.checkoutAddress &&
      String(prevProps.checkoutAddress.storeId) !==
        String(this.props.checkoutAddress.storeId)
    ) {
      this.updateStockState()
    }

    if (
      this.props.details &&
      prevProps.details &&
      prevProps.details.id !== this.props.details.id
    ) {
      this.updateStockState()
    }

    /* when the stock props is updated yet the value props is not updated
    between prevProps & this.props so this explicit check */
    if (prevProps.value !== 0 && this.props.value === 0) {
      this.updateStockState()
    }

    if (
      prevProps.stock !== this.props.stock ||
      prevProps.unlimitedStock !== this.props.unlimitedStock
    ) {
      this.updateStockState()
    }
  }

  updateStockState = () => {
    const {
      value,
      stock,
      unlimitedStock,
      bulkOrderThreshold,
      storeBulkOrderThreshold,
      checkoutAddress,
    } = this.props
    const isOutOfStockState = isOutOfStock({
      value,
      stock,
      unlimitedStock,
      bulkOrderThreshold,
      storeBulkOrderThreshold,
      checkoutAddress,
    })

    this.setState({ isOutOfStockState: isOutOfStockState })
  }

  handleOpenAddressPopup = () => {
    this.setState({ isShownAddressPopup: true })
  }

  handleCloseAddressPopup = () => {
    this.setState({ isShownAddressPopup: false })
  }

  addToCart = (value, quanitity) => {
    if (typeof this.props.onValueChange === 'function') {
      this.props.onValueChange({
        prevValue: value,
        nextValue: quanitity ? value + quanitity : value + 1,
        delta: quanitity ? quanitity : 1,
      })
    }
  }

  handleAddToCartClick = (event, value, suggestedQuantity) => {
    event.preventDefault()
    event.stopPropagation()
    const { checkoutAddress } = this.props
    if (!checkoutAddress || !checkoutAddress.address) {
      this.handleOpenAddressPopup()
      return
    }

    this.addToCart(value, suggestedQuantity)
  }

  generateButtonTemplate(ButtonControl = StyledCartButton) {
    const {
      value,
      isVerticalCard,
      renderAddToCartSection: AddToCartSection,
      suggestedQuantity,
      layoutTypeVoucher,
    } = this.props
    return (
      <StyledCart
        layoutTypeVoucher={layoutTypeVoucher}
        isVerticalCard={isVerticalCard}
      >
        {AddToCartSection ? (
          <AddToCartSection
            value={value}
            onAddToCartClick={event =>
              this.handleAddToCartClick(event, value, suggestedQuantity)
            }
          />
        ) : (
          <ButtonControl
            data-testid="SvgAddToCart"
            isVerticalCard={isVerticalCard || false}
            onClick={event =>
              this.handleAddToCartClick(event, value, suggestedQuantity)
            }
          >
            <SmallCartIcon width="24" height="24" title="add to cart" />
            <LargeCartIcon width="36" height="36" title="add to cart" />
            <StyledText size="large" weight="regular">
              Add to cart
            </StyledText>
          </ButtonControl>
        )}
      </StyledCart>
    )
  }

  handleChangeQuantity = ({ prevValue, nextValue, delta }) => {
    const { checkoutAddress } = this.props

    if (!checkoutAddress || !checkoutAddress.address) {
      this.handleOpenAddressPopup()
      return
    }

    this.props.onValueChange &&
      this.props.onValueChange({
        prevValue,
        nextValue,
        delta,
      })
  }

  render() {
    const {
      value,
      hasSteppers,
      buttonStyle,
      stock,
      price,
      noStyle,
      counterStyle,
      isCart,
      unlimitedStock,
      bulkOrderThreshold,
      quantityAdjustedmessage,
      outOfStockStyle: OutOfStockStyle,
      orderedProduct,
      orderStatus,
      isSoldByWeight,
      readOnlyCounter,
      storeBulkOrderThreshold,
      details,
      organizationData,
      checkoutAddress,
      update,
      maxPurchasableStock,
      isVerticalCard,
      suggestedQuantity,
      alwaysShowAddToCartButton,
      renderOutOfStockSection: OutOfStockSection = null,
      layoutTypeVoucher,
    } = this.props

    if (this.state.isOutOfStockState) {
      if (OutOfStockSection) {
        return <OutOfStockSection />
      }
      return (
        <OutOfStockStyle
          isVerticalCard={isVerticalCard || false}
          layoutTypeVoucher={layoutTypeVoucher}
        >
          <Text>Out of stock</Text>
        </OutOfStockStyle>
      )
    }

    if (price !== undefined && Number(price) === 0) {
      return null
    }

    const isInPfc = checkoutAddress.storeId === organizationData.defaultStoreId

    const { isShownAddressPopup } = this.state

    const CounterStyle = counterStyle || StyledCounter

    const mobileStepperSize = 32

    return (
      <React.Fragment>
        {isShownAddressPopup && (
          <AddressPopup
            checkoutAddress={checkoutAddress}
            onHandleClose={this.handleCloseAddressPopup}
            updateCheckoutAddress={(
              newCheckoutAddress,
              syncCart,
              isAddressManuallyChanged
            ) => {
              update(newCheckoutAddress, syncCart, isAddressManuallyChanged)
              this.addToCart(value, suggestedQuantity)
            }}
          />
        )}
        {(value === VALUE_TO_SHOW_CART_IMAGE && !orderedProduct) ||
        alwaysShowAddToCartButton ? (
          this.generateButtonTemplate(buttonStyle, stock)
        ) : (
          <CounterStyle
            noStyle={noStyle}
            isVerticalCard={isVerticalCard || false}
            layoutTypeVoucher={layoutTypeVoucher}
            data-testid="counter-wrapper"
          >
            <Counter
              value={value}
              onChange={this.handleChangeQuantity}
              hasSteppers={hasSteppers}
              height={40}
              width={40}
              stock={stock}
              isCart={isCart}
              maxPurchasableStock={maxPurchasableStock}
              bulkOrderThreshold={bulkOrderThreshold}
              unlimitedStock={unlimitedStock}
              storeBulkOrderThreshold={storeBulkOrderThreshold}
              quantityAdjustedmessage={quantityAdjustedmessage}
              query={SCREEN_SIZE.From.MobileL}
              showInRed={
                orderedProduct && !value && orderStatus === 'CANCELLED'
              }
              isSoldByWeight={isSoldByWeight}
              readOnlyCounter={readOnlyCounter}
              productId={details && details.id}
              isInPfc={isInPfc}
              layoutTypeVoucher={layoutTypeVoucher}
            />
            <Counter
              value={value}
              onChange={this.handleChangeQuantity}
              hasSteppers={hasSteppers}
              height={mobileStepperSize}
              width={mobileStepperSize}
              stock={stock}
              isCart={isCart}
              maxPurchasableStock={maxPurchasableStock}
              bulkOrderThreshold={bulkOrderThreshold}
              unlimitedStock={unlimitedStock}
              storeBulkOrderThreshold={storeBulkOrderThreshold}
              quantityAdjustedmessage={quantityAdjustedmessage}
              isInProductDetail={CounterStyle === StyledCounterInProductDetail}
              query={SCREEN_SIZE.Only.Mobile}
              showInRed={
                orderedProduct && !value && orderStatus === 'CANCELLED'
              }
              isSoldByWeight={isSoldByWeight}
              readOnlyCounter={readOnlyCounter}
              productId={details && details.id}
              isInPfc={isInPfc}
              layoutTypeVoucher={layoutTypeVoucher}
            />
          </CounterStyle>
        )}
      </React.Fragment>
    )
  }
}

CartCounter.defaultProps = {
  value: 0,
  outOfStockStyle: StyledOutOfStockInProductDetail,
  handlingDays: 0,
  alwaysShowAddToCartButton: false,
  layoutTypeVoucher: '',
}

const CartCounterWrapper = props => (
  <GlobalContext.Consumer>
    {({ organizationData }) => (
      <CheckoutAddressConsumer>
        {({ checkoutAddress, update }) => (
          <EventTrackingConsumer>
            {({ track }) => (
              <CartCounter
                {...props}
                organizationData={organizationData}
                checkoutAddress={checkoutAddress}
                update={update}
                track={track}
              />
            )}
          </EventTrackingConsumer>
        )}
      </CheckoutAddressConsumer>
    )}
  </GlobalContext.Consumer>
)

export default CartCounterWrapper
